import BaseCtrl from 'edge/platform/base/BaseCtrl';
import PolicyChange from 'edge/endorsement/common/models/PolicyChange';
import LocalDateUtil from 'gw-portals-util-js/LocalDateUtil';
import LocalTime from 'edge/platform/time/LocalTime';

const PAYMENT_PLAN_ANNUAL = 'bc:5';

const fn = function ($rootScope, $scope, $state, $stateParams, $sce, EndorsementService, $translate, brandingData, ViewModelService, StateUtils, ScrollService_AND, $q, StaticDocumentsService_AND, PolicyService) {
    const ctrl = Object.create(BaseCtrl);
    let oosErrorMsg;
    $translate(`and.endorsement.common.view.PolicyChange.OOSError.${brandingData}`)
        .then((result) => {
            oosErrorMsg = result;
        });
    const VIEWS = {
        // Initial screen
        SELECT_CHANGES: 'select-changes',
        // Coverables
        ADDRESS: 'address',
        DRIVERS: 'drivers',
        VEHICLE: 'vehicle',
        // Price and Purchase
        QUOTE: 'quote',
        IMPORTANT_INFO: 'important-info',
        CARD_SELECTION: 'card-selection',
        LEGAL_PAYMENT: 'legal-payment',
        PAYMENT: 'payment',
        PAYMENT_COMPLETE: 'payment-complete',
        // Errors
        CALL_SC: 'call-sc', // Call Service Centre
        PAYMENT_FAILED: 'payment-failed',
        QUOTE_DECLINED: 'quote-declined',
        QUOTE_DECLINED_UW: 'quote-decline-uw'
    };

    const retrieveDocs = () => $q.all([
        StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.IMPORTANT_INFORMATION)
    ])

    .then((result) => {
        $scope.importantInfoDoc = result[0];
    })

    retrieveDocs();

    ctrl.init = () => {
        if ($rootScope.policyContext && $rootScope.policyContext.currentPolicy) {
            const policyDetailsVM = $rootScope.policyContext.currentPolicy.periods[0].policyDetailsVM;
            return load().then(init(policyDetailsVM));
        }
        return $state.go('yourInsurance_AND');
    };
    ctrl.changeVehicle = changeVehicle;
    ctrl.changeDrivers = changeDrivers;
    ctrl.changeOvernightAddress = changeOvernightAddress;
    ctrl.goToQuotePage = goToQuotePage;
    ctrl.clearValidationMsg = clearValidationMsg;

    return ctrl.create($scope);

    function init(policyDetailsVM) {
        const nextBtnNoPaymentConfig = {nextCss: 'and-endorsement-buy-now-btn'};
        $rootScope.policyContext.isInPolicyChangeWizard = true; // Manually set PC wizard
        // Local scope
        $scope.minEffectiveDate = LocalDateUtil.today(); // Today
        $scope.maxEffectiveDate = policyDetailsVM.maximumMTAStartDate_itb.value;

        $scope.effectiveDate = {value: $scope.minEffectiveDate}; // Default today
        $scope.$watch(() => $scope.effectiveDate.value, () => {
            if ($scope.page.policyChangeVM.value) {
                $scope.page.policyChangeVM.baseData.effectiveDate.value = $scope.effectiveDate.value;
            }
        });

        PolicyService.getModificationLists($rootScope.productCode).then((data) => {
             $scope.page.afterMarketOptions = data[0];
             $scope.page.bodyworkModifications = data[1];
             $scope.page.engineModifications = data[2];
             $scope.page.wheelsOrTyresModifications = data[3];
             $scope.page.conversionModifications = data[4];
             $scope.page.otherModifications = data[5];
        });
        if ($stateParams.changeConfirmationCallback) {
           $stateParams.changeConfirmationCallback(
               $scope.page.selectedVehicle,
               $scope.page.driver,
               $scope.page.afterMarketOptions,
               $scope.page.bodyworkModifications,
               $scope.page.engineModifications,
               $scope.page.wheelsOrTyresModifications,
               $scope.page.conversionModifications,
               $scope.page.otherModifications
           );
        }

        $scope.effectiveTime = {
            value: LocalDateUtil.now_AND(30),
            aspects: {
                required: true,
                get valid() {
                    return !!$scope.effectiveTime.value;
                }
            }
        };
        $scope.$watch(() => $scope.effectiveTime.value, () => {
            if ($scope.page.policyChangeVM.value) {
                $scope.page.policyChangeVM.baseData.effectiveTime_itb.value = LocalTime.fromJSDate($scope.effectiveTime.value);
            }
        });

        const effectiveDate = moment(Object.assign({}, $scope.effectiveDate.value, $scope.effectiveTime.value));
        $scope.mtaEffectiveDate = effectiveDate.format('DD MMMM YYYY');


        // Scroll to top on each page change
        $scope.$watch(() => $scope.wizardTransitionPromise, () => ScrollService_AND.scrollToTop());
        // Scope cleanup
        $scope.$on('$destroy', () => {
            $rootScope.policyContext.isInPolicyChangeWizard = false; // Manually unset PC wizard
            StateUtils.removeActiveDataForState('model', true);
        });

        $scope.scrollToTop = () => ScrollService_AND.scrollToTop();

        window.addEventListener('message', (event) => {
            if (event.data === 'scrollToTop') {
                setTimeout(() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }, 5000);
            }
        });

        // Payment gateway acknowledge functions
        window.ackPaymentSuccess_AND = function () {
            bindAndGoToNext();
        };

        window.ackPaymentFailure_AND = function () {
            // This call does not go through any AngularJs code so needs an explicit digest.
            $scope.$apply(() => goTo(VIEWS.PAYMENT_FAILED));
        };

        // Shared with children scopes
        $scope.page = {
            // Model
            view: VIEWS.SELECT_CHANGES,
            policyDetailsVM,
            policyChangeVM: {},
            bindDataVM: ViewModelService.create({}, 'pc', 'edge.capabilities.policychange.binding.dto.BindDataDTO'),
            hasVehicleChanged: function () {
                if (this.policyChangeVM.lobData.personalMotor.coverables.pmVehicles.children.length > 0) {
                   
                    if (typeof this.policyChangeVM.lobData.personalMotor.coverables.pmVehicles.children[0].hasChanged_itb.value === 'undefined') {
                        return false;
                    }
                    else {
                        return this.policyChangeVM.lobData.personalMotor.coverables.pmVehicles.children[0].hasChanged_itb.value;
                    } 
                }
                else {
                    return false;
                }
            },
            hasAddressChanged: function () {
                return $scope.page.policyChangeVM.baseData.policyAddress.addressLine1.value + $scope.page.policyChangeVM.baseData.policyAddress.postalCode.value !==
                       $scope.page.policyDetailsVM.policyAddress.addressLine1.value + $scope.page.policyDetailsVM.policyAddress.postalCode.value; 
            },
            haveDriversBeenAdded: function () {
                const basedOnDrivers = this.policyDetailsVM.lobs.personalMotor.drivers.value;
                const currentDrivers = this.policyChangeVM.lobData.personalMotor.coverables.pmDrivers.value;
                // If this Job has more drivers, guaranteed new drivers exist
                if (currentDrivers.length > basedOnDrivers.length) {
                    return true;
                }
                // If not, check if there are drivers on this Job that do not exist on basedOn
                for (const currentDriver of currentDrivers) {
                    if (!basedOnDrivers.find((basedOnDriver) => basedOnDriver.fixedId === currentDriver.fixedId)) {
                        return true;
                    }
                }
                return false;
            },
            hasAdditionalDriverChanged: function () {
                const basedOnNonPH = $scope.page.policyDetailsVM.lobs.personalMotor.drivers.children.filter((d) => d.isPolicyHolder.value !== true); // policyDetailsVM info
                const currentNonPH = this.policyChangeVM.lobData.personalMotor.coverables.pmDrivers.filter((d) => d.isPolicyHolder.value !== true); // portalVm info
                const drivers = [];
                currentNonPH.forEach((d) => basedOnNonPH.forEach((d1) => { // cycle through nonPolicyHolder drivers on PolicyChangeVM that are on Policy before MTA
                    if (d.value.fixedId === d1.value.fixedId) {
                        if (d !== d1) {
                            drivers.push(d.value);
                        }
                    }
                }));
                return drivers.length > 0;
            },
            // Navigation
            save: function () {
                if ($scope.page.policyChangeVM.aspects.valid && $scope.page.policyChangeVM.aspects.subtreeValid) {
                    $scope.wizardTransitionPromise = EndorsementService.saveEndorsement($scope.page.policyChangeVM.value)
                        .then((model) => updateModel(model))
                        .catch(() => {
                            goTo(VIEWS.CALL_SC);
                            throw new Error(); // Ensure chained promises fail too
                        });
                    return $scope.wizardTransitionPromise;
                }
                const deferred = $q.defer();
                deferred.reject();
                return deferred.promise;
            },
            saveAndGoToChangeSelection: function () {
                return $q.when($scope.page.save())
                    .then(() => goTo(VIEWS.SELECT_CHANGES));
            },
            reviewVehicle: function () {
                if ($scope.page.view === VIEWS.QUOTE) {
                    goTo(VIEWS.VEHICLE);
                }
            },
            reviewDrivers: function () {
                if ($scope.page.view === VIEWS.QUOTE) {
                    goTo(VIEWS.DRIVERS);
                }
            },
            reviewPHDriver: function () {
                if ($scope.page.view === VIEWS.QUOTE) {
                    $scope.page.initDriversOnPH = true;
                    goTo(VIEWS.DRIVERS);
                }
            },
            goToChangeSelection: function () {
                goTo(VIEWS.SELECT_CHANGES);
            },
            goToCallSC: function () {
                return goTo(VIEWS.CALL_SC);
            },
            nextBtnNoPayment: function () {
                if ($scope.page.is0AP === true) {
                    nextBtnNoPaymentConfig.nextLabel = 'and.endorsement.common.directives.wizard-page-actions-mta.Finish';
                    return nextBtnNoPaymentConfig;
                }

                if ($scope.page.isAnnualPaymentPlan !== true && $scope.page.remainingInstalmens > 0) {
                    if ($scope.page.isRP === true) {
                        nextBtnNoPaymentConfig.nextLabel = 'and.endorsement.common.directives.wizard-page-actions-mta.Refund';
                    } else {
                        nextBtnNoPaymentConfig.nextLabel = 'and.endorsement.common.directives.wizard-page-actions-mta.BuyNow';
                    }
                    return nextBtnNoPaymentConfig;
                }
            },
            goToNext: function () {
                switch ($scope.page.view) {
                    case VIEWS.QUOTE:
                        const deferred = $q.defer();
                        // CBPUAT-1261: Bypass PCL and instalments validation if on Annual plan or it's a 0P change.
                        if ($scope.page.isAnnualPaymentPlan === true || $scope.page.is0AP === true) {
                            deferred.resolve();
                        } else {
                            // This call is here to fail to Error screen in case it's invalid
                            $scope.wizardTransitionPromise = EndorsementService.validatePCLAndGetRemainingInstalments_AND($scope.page.policyChangeVM.jobID.value)
                                .then((remainingInstalments) => {
                                    $scope.page.remainingInstalmens = remainingInstalments;
                                    deferred.resolve();
                                })
                                .catch((error) => deferred.reject(error));
                        }

                        return deferred.promise
                            .then(() => {
                                if (brandingData === 'tbb' || brandingData === 'dgt') {
                                    if (this.hasVehicleChanged() || this.haveDriversBeenAdded() || this.hasAddressChanged() || this.hasAdditionalDriverChanged()) {
                                        return goTo(VIEWS.IMPORTANT_INFO);
                                    } else {
                                        return startPaymentFlow();
                                    }
                                }
                                return startPaymentFlow();
                            })
                            .catch(() => goTo(VIEWS.CALL_SC));
                            
                    case VIEWS.IMPORTANT_INFO: brandingData == 'tbb' || brandingData === 'dgt';
                        return startPaymentFlow();
                    case VIEWS.CARD_SELECTION:
                        if ($scope.page.bindDataVM.paymentDetails.reuseExistingCard_itb.value === true) {
                            return bindAndGoToNext();
                        }
                        return goTo(VIEWS.LEGAL_PAYMENT);
                    case VIEWS.LEGAL_PAYMENT:
                        $scope.wizardTransitionPromise = EndorsementService.preBind_AND($scope.page.policyChangeVM.jobID.value, $scope.page.bindDataVM.value)
                            .then((url) => {
                                $scope.gatewayURL = trustSrc(url);
                                return goTo(VIEWS.PAYMENT);
                            })
                            .catch(() => goTo(VIEWS.CALL_SC));
                        return $scope.wizardTransitionPromise;
                    default: goTo(VIEWS.CALL_SC);
                }
            }
        };
    }

    function startPaymentFlow() {
        if ($scope.page.is0AP === true) {
            return bindAndGoToNext();
        } else if ($scope.page.isAnnualPaymentPlan === true) {
            $scope.wizardTransitionPromise = EndorsementService.isSavedCardValid_AND($scope.page.policyChangeVM.jobID.value)
                .then((isValid) => {
                    if (!$scope.page.bindDataVM.paymentDetails.value) {
                        $scope.page.bindDataVM.paymentDetails.value = {};
                    }
                    // If AP and card is valid customer has the choice of reusing or add a new card
                    if ($scope.page.isPosAP === true) {
                        if (isValid === true) {
                            return goTo(VIEWS.CARD_SELECTION); // Allow choice
                        }
                        $scope.page.bindDataVM.paymentDetails.reuseExistingCard_itb.value = false;
                        return goTo(VIEWS.LEGAL_PAYMENT); // Force new card
                    }
                    // If RP the card must be valid (to refund existing), otherwise go to error
                    if (isValid === true) {
                        return bindAndGoToNext();
                    }
                    return goTo(VIEWS.CALL_SC);
                })
                .catch(() => goTo(VIEWS.CALL_SC));
            return $scope.wizardTransitionPromise;
        } else if ($scope.page.remainingInstalmens >= 2) { // Payment/Refund handled through instalments
            return bindAndGoToNext();
        } else if ($scope.page.remainingInstalmens < 2) {
            return goTo(VIEWS.CALL_SC);
        }
        // Payment/Refund cannot be handled through instalments, go through card flow
        if ($scope.page.isPosAP === true) {
            if (!$scope.page.bindDataVM.paymentDetails.value) {
                $scope.page.bindDataVM.paymentDetails.value = {};
            }
            return goTo(VIEWS.CARD_SELECTION);
        }
        return bindAndGoToNext();
    }

    function goTo(view) {
        $scope.page.view = view;
        ScrollService_AND.scrollToTop(); // Scroll to top on each page change
    }

    function goToQuotePage() {
        validateEffDateTime();
        if ($scope.validationErrorMsg) {
            return;
        }
        if ($scope.page.policyChangeVM && $scope.page.policyChangeVM.upFrontPaymentAttempted_itb && $scope.page.policyChangeVM.upFrontPaymentAttempted_itb.value === true) {
            goTo(VIEWS.CALL_SC);
        } else if ($scope.page.policyChangeVM.status.value.code === 'Quoted') {
            goTo(VIEWS.QUOTE);
        } else {
            if ($scope.page.hasVehicleChanged() && $scope.page.policyChangeVM.lobData.personalMotor.coverables.pmVehicles.children[0].eligibleForPlugInHybridInsurance.value) {
                $scope.page.policyChangeVM.lobData.personalMotor.coverables.pmVehicles.children[0].plugInHybridInsurance.value = false;
            }
            if ($scope.page.hasVehicleChanged() && $scope.page.policyChangeVM.lobData.personalMotor.coverables.pmVehicles.children[0].eligibleForFullHybridInsurance.value) {
                $scope.page.policyChangeVM.lobData.personalMotor.coverables.pmVehicles.children[0].fullHybridInsurance.value = false;
            }
            if ($scope.page.hasVehicleChanged() && $scope.page.policyChangeVM.lobData.personalMotor.coverables.pmVehicles.children[0].eligibleForUsageBasedInsurance.value) {
                $scope.page.policyChangeVM.lobData.personalMotor.coverables.pmVehicles.children[0].usageBasedInsurance.value = false;
            }
            $scope.wizardTransitionPromise = EndorsementService.quoteEndorsement($scope.page.policyChangeVM.value)
                .then((pc) => updateModel(pc))
                .then(() => goTo(VIEWS.QUOTE))
                .catch((error) => {
                    if (error && error.baseError && error.baseError.error && error.baseError.error.data) {
                        switch (error.baseError.error.data.appErrorCode) {
                            case 608: // GW_ENTITY_VALIDATION_ERROR
                            case 602: // GW_UNDERWRITING_ERROR
                            case 631: // AND_HIGH_RISK_VALIDATION_EXCEPTION
                                goTo(VIEWS.QUOTE_DECLINED_UW);
                                break;
                            case 611: // GW_BLOCK_QUOTE_UNDERWRITING_ERROR
                                goTo(VIEWS.QUOTE_DECLINED);
                                break;
                            default: // Technical error
                                goTo(VIEWS.CALL_SC);
                        }
                    } else { // Technical error
                        goTo(VIEWS.CALL_SC);
                    }
                });
        }
    }

    function updateModel(model) {
        // Update scope's variables
        $scope.page.policyChangeVM = ViewModelService.create(new PolicyChange(model), 'pc', 'edge.capabilities.policychange.dto.PolicyChangeDataDTO');
        StateUtils.activeDataForState(true).model = $scope.page.policyChangeVM;
        // Update evaluations
        if ($scope.page.policyChangeVM.changeInCost_itb && $scope.page.policyChangeVM.changeInCost_itb.amount) {
            const totalCost = $scope.page.policyChangeVM.totalCost.amount.value;
            // Is Return Premium, i.e. there is an amount to refund
            $scope.page.isRP = totalCost < 0;
            // Is positive Additional Premium
            $scope.page.isPosAP = totalCost > 0;
            // Is 0 Additional Premium
            $scope.page.is0AP = totalCost === 0;
        }
        if ($scope.page.policyChangeVM.paymentPlanID) {
            $scope.page.isAnnualPaymentPlan = $scope.page.policyChangeVM.paymentPlanID.value === PAYMENT_PLAN_ANNUAL;
        }
        return $scope.page.policyChangeVM;
    }

    function changeDrivers() {
        loadWithDate().then(() => goTo(VIEWS.DRIVERS));
    }

    function changeVehicle() {
        loadWithDate().then(() => goTo(VIEWS.VEHICLE));
    }

    function changeOvernightAddress() {
        loadWithDate().then(() => goTo(VIEWS.ADDRESS));
    }

    function loadWithDate() {
        $scope.oosErrorMsg = undefined;
        if ($scope.page.policyChangeVM.jobID && $scope.page.policyChangeVM.jobID.value) { // If a job was already created
            const deferred = $q.defer();
            deferred.resolve();
            return deferred.promise;
        }
        $scope.wizardTransitionPromise = EndorsementService.loadEndorsementWithEffectiveDateTime(
            $rootScope.policyContext.currentPolicyNumber,
            $scope.effectiveDate.value,
            LocalTime.fromJSDate($scope.effectiveTime.value)
        )
            .then((pc) => updateModel(pc))
            .catch((error) => {
                if (error && error.baseError && error.baseError.error && error.baseError.error.data &&
                    error.baseError.error.data.appErrorCode === 621) {
                    $scope.oosErrorMsg = oosErrorMsg;
                } else if (error && error.baseError && error.baseError.error && error.baseError.error.data) {
                    switch (error.baseError.error.data.appErrorCode) {
                        case 608: // GW_ENTITY_VALIDATION_ERROR
                        case 602: // GW_UNDERWRITING_ERROR
                        case 631: // AND_HIGH_RISK_VALIDATION_EXCEPTION
                            goTo(VIEWS.QUOTE_DECLINED_UW);
                            break;
                        default: // Technical error
                            goTo(VIEWS.CALL_SC);
                    }
                } else {
                    goTo(VIEWS.CALL_SC);
                }
                throw error;
            });
        return $scope.wizardTransitionPromise;
    }

    /**
     * Perform basic initial validations, such as verifying if a PolicyChange can be started at all
     * @returns {*} promise
     */
    function load() {
        $scope.wizardTransitionPromise = EndorsementService.loadEndorsement($rootScope.policyContext.currentPolicyNumber)
            .then((model) => updateModel(model))
            .catch(() => goTo(VIEWS.CALL_SC)); // Go to "Call the service centre" page
        return $scope.wizardTransitionPromise;
    }

    function validateEffDateTime() {
        $scope.validationErrorMsg = undefined;
        const effDateTime = new Date(
            $scope.effectiveDate.value.year,
            $scope.effectiveDate.value.month,
            $scope.effectiveDate.value.day,
            $scope.effectiveTime.value.getHours(),
            $scope.effectiveTime.value.getMinutes()
        );
        const minEffDateTime = LocalDateUtil.now_AND(15);
        const maxEffDateTime = LocalDateUtil.toMidnightDate($scope.maxEffectiveDate);
        maxEffDateTime.setHours(minEffDateTime.getHours(), minEffDateTime.getMinutes(), 0, 0);

        if (effDateTime < minEffDateTime) {
            $scope.validationErrorMsg = 'and.endorsement.common.view.PolicyChange.Validation.PastDate';
        }
        if (effDateTime > maxEffDateTime) {
            $scope.validationErrorMsg = 'and.endorsement.common.view.PolicyChange.Validation.FutureDate';
        }
    }

    function clearValidationMsg() {
        $scope.validationErrorMsg = undefined;
    }

    /**
     * Escapes <code>url</code>.
     * https://docs.angularjs.org/api/ng/service/$sce
     * @param {String} url URL to escape
     * @returns {*} Wrapped URL
     */
    function trustSrc(url) {
        return $sce.trustAsResourceUrl(url);
    }

    function bindAndGoToNext() {
        $scope.wizardTransitionPromise = EndorsementService.bind($scope.page.policyChangeVM.jobID.value, $scope.page.bindDataVM.paymentDetails.value)
            .then((model) => {
                updateModel(model.policyChange);
                goTo(VIEWS.PAYMENT_COMPLETE);
            })
            .catch(() => goTo(VIEWS.CALL_SC));
        return $scope.wizardTransitionPromise;
    }
};

fn.$inject = ['$rootScope', '$scope', '$state', '$stateParams', '$sce', 'endorsement.EndorsementService', '$translate',
    'brandingData', 'ViewModelService', 'StateUtils', 'ScrollService_AND','$q', 'StaticDocumentsService_AND', 'qnb.PolicyService'];

export default fn;