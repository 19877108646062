import templateStr from 'text!./pm-payment-banner-mta_AND.html';

export default [() => {
    return {
        restrict: 'A',
        scope: {
            page: '='
        },
        template: templateStr,
        controller: ['$scope', ($scope) => {
           
        }]
    };
}];